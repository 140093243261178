.chat {
  padding-top: 0;
  .ant-card-body {
    min-height: 530px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;
    overflow: hidden;
    border-radius: 16px;
  }


  &__top {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    padding: 24px;
    border-bottom: 1px solid rgba($darkGreyColor, .5);
    backdrop-filter: blur(7px);
    background: rgba(#fff, .6);
    z-index: 10;
  }

  &__info-labels {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  &__type {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 10px;

    .ant-btn {
      margin-right: 10px;
      font-size: 12px;
    }
  }

  &__info-label + &__info-label {
    margin-left: 10px;
  }
  &__content {
    padding-top: 145px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: calc(100vh - 260px);
    overflow-y: auto;
    min-height: 350px;
    padding-bottom: 20px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
  &__footer {
    border-top: 1px solid rgba($darkGreyColor, .5);
    width: calc(100% + 48px);
    padding: 24px;
    padding-top: 35px;
    padding-bottom: 0;
    margin-left: -24px;
    position: relative;
  }

  &__close-ticket {
    &.ant-btn {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      color: $primaryMain;
      border-color: $primaryMain;
      line-height: 1;
      font-size: 18px;
      height: 45px;
      .anticon {
        width: 24px;
        height: 24px;
        flex: none;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      &:hover {
        color: $primaryActiveColor;
        border-color: $primaryActiveColor;
      }

    }
  }

  &__settings {
    &.ant-btn {
      height: 45px;
      width: 45px;
      margin-left: 10px;
    }
  }

  &__edit-msg {
    position: absolute;
    left: 0;
    top: 5px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    padding: 0 24px;
    width: 100%;
    font-size: 13px;
  }
  &__edit-text {
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-style: italic;
    font-weight: bold;
    font-size: 15px;
  }
  &__edit-close {
    border: none;
    font-size: 18px;
    background: none;
    cursor: pointer;
    border-radius: 50%;
    flex: none;
    margin-right: 10px;
    padding: 0;
    transition: transform .3s ease, background .3s ease;
    &:hover {
      color: $primaryMain;
      transform: scale(1.1);
    }
  }
  &__edit-icon {
    margin: 0 5px;
    flex: none;
  }
}

.chat-controls {
  position: absolute;
  right: 24px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
}