.auth-page {
  .ant-card-head-title {
    color: #fff;
  }
  .ant-card {
    background: $darkBox;
    color: #fff;
    border: none;
  }
  .ant-card-head-title {
    font-size: 21px;
    font-weight: bold;
    padding: 0;
  }
  .ant-card-body,
  .ant-card-head {
    padding: 0;
  }

  .ant-card-head,
  .ant-checkbox-wrapper {
    color: $white;
  }
  .ant-card {
    background: transparent;
    border: none;
    color: $white;
    text-align: left;
    &.ant-card-bordered {
      border-color: $darkInput;
    }
  }
}
.auth-page-content {
  display: flex;
  justify-content: center;
  padding: 30px 0;
  &__info {
    width: 400px;
    align-self: center;
    flex: none;
    @include media("<1200px") {
      width: 100%;
      margin-top: 0;
    }
  }
  &__image {
    max-width: 600px;
    margin-left: -350px;
    @include media("<1200px") {
      display: none;
    }
    img {
      max-width: 100%;
    }
  }
}
.auth-form {

  .ant-btn {
    margin: 0 auto;
    margin-bottom: 15px;
    width: 150px;
    justify-content: center;
  }

  .ant-form-item-explain {
    font-size: 10px;
    margin-top: 5px;
    min-height: initial;
  }
  .ant-form-item {
    display: block;
    >* {
      width: 100%;
      max-width: 100%;
    }
  }

  .ant-form-item-label > label {
    @include media("<568px") {
      height: auto;
    }
  }

  a {
    color: $secondaryColor;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .ant-form-item-explain {
    font-size: 10px;
    padding: 0;
    margin-top: 5px;
  }
  .ant-input{
    border-radius: 10px;
    background-color: $darkBox !important;
    border: 1px solid transparent;
    box-shadow: none !important;
    outline: none !important;
    color: #fff;
    height: 40px !important;
    margin: 0!important;
    padding-left: 15px !important;
    &:hover, &:focus {
      border-color: rgba(250, 250, 250, .5);
    }
  }
  &__footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    .btn {
      width: calc(50% - 10px);
    }
    a {
      width: 100%;
      margin-top: 5px;
      display: block;
    }
  }
  .btn {
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .ant-input-affix-wrapper{
    border-radius: 5px;
    background-color: $darkBox !important;
    border: 1px solid transparent;
    box-shadow: none !important;
    outline: none !important;

    .ant-input-password-icon {
      color: rgba(250, 250, 250, .5);
    }
    .ant-input-suffix {
      margin-right: 5px;
    }

    &:hover, &:focus {
      border-color: rgba(250, 250, 250, .5);
    }

    > input.ant-input {
      &:hover, &:focus {
        border-color: transparent;
      }
    }
  }
}

.recaptcha, .auth-check {

  .ant-form-item-control-input-content {
    @include flex($justify-content: center);
  }
  .ant-form-item-explain {
    text-align: center;
  }
}