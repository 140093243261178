.admin-stats-grid {
  display: flex;
  margin: 0 -5px;
  padding-top: 10px;
  flex-wrap: wrap;

  .ant-card-body {
    padding: 10px;
  }

  &__item {
    width: 25%;
    padding: 0 5px;
    padding-bottom: 10px;

    @include media("<1440px") {
      width: 50%;
    }
    @include media("<768px") {
      width: 100%;
    }
  }
}

.admin-stats-card {
  &__title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
    font-family: $mainSansSerifBold;
  }
}

.admin-stats-item {
  + * {
    border-top: 1px dashed rgba($darkBox, .1);

  }

  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6) {
    border-color: transparent !important;
    margin-bottom: 1px;
    .admin-stats-item {
      &__user {
        font-family: $mainSansSerifBold;
        color: darken($primaryColor, 15%);
      }
    }
    +* {
      border-color: transparent !important;
    }
  }
  &:nth-child(2){
    background-color: rgba($primaryColor, .5);
  }
  &:nth-child(3){
    background-color: rgba($primaryColor, .4);
  }
  &:nth-child(4){
    background-color: rgba($primaryColor, .3);
  }
  &:nth-child(5){
    background-color: rgba($primaryColor, .2);
  }
  &:nth-child(6){
    background-color: rgba($primaryColor, .1);
  }

  display: flex;
  align-items: center;
  padding: 3px 5px;
  font-size: 11px;
  border-radius: 5px;
  width: calc(100% + 10px);
  margin-left: -10px;

  > * {
    margin-bottom: 0;

  }

  &__number {
    width: 20px;
    flex: none;
    margin-right: 10px;
    font-size: 12px;
    font-family: $mainSansSerifBold;
  }

  &__user {
    text-decoration: none;

    &:hover {
      color: $primaryMain;
      text-decoration: none;
    }
  }

  &__value {
    margin-left: auto;
  }
}
.admin-stats-card {
  .ant-card-body {
    padding: 15px 24px;
  }
  &__top {
    display: flex;
    align-items: center;
    p {
      font-size: 16px;
      font-weight: 600;
      margin: 0;
      margin-right: 15px;
      font-family: $mainSansSerifBold;
    }
  }
}
strong {
  font-family: $mainSansSerifBold;

}