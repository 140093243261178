.faq-list-item {
  margin-top: 20px;

  &__edit {
    margin-left: auto;
    margin-right: 10px;
  }

  &__top {
    display: flex;
    align-items: center;
    .ant-switch {
      margin-right: 10px;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    margin-left: 20px;
    font-size: 16px;
    margin-right: auto;

    .anticon {
      margin-right: 5px;
    }

    .ant-typography {
      margin: 0;
      margin-left: 10px;
    }
  }
  &__content {
    font-style: italic;
    font-size: 12px;
  }
}

.faq-list-pagination {
  text-align: center;
  margin-top: 20px;
}