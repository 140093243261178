.cell-sports {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  white-space: nowrap;
  &__item{
    +* {
      margin-left: 10px;
      padding-left: 10px;
      border-left: 1px solid rgba(0, 0, 0, 0.3);
    }
  }
  &__title {
    font-size: 9px;
    color: rgba(0, 0, 0, 0.45);
    font-style: italic;
    margin: 0;
    line-height: 1;
  }
  &__value {
    font-weight: bold;
    margin: 0;
    font-size: 10px;
    color: rgba(0, 0, 0, 0.65);
  }
}