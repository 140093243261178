.date-range-picker {


  &.ant-picker {
    height: 45px;
    display: flex;
    align-items: center;
    border-radius: 5px;

    &:hover {
      border-color: $primaryColor;
    }

    &.ant-picker-focused {
      border-color: $primaryColor;
      box-shadow: 0 0 7px -3px $primaryColor;
    }
  }

  .ant-picker-input > input {
    text-align: center;
  }

  .ant-picker-active-bar {
    background: $primaryColor;
  }
}
