.chats-nav {
  display: flex;

  margin-top: -24px;
  list-style: none;
  padding: 15px 0;
  max-width: 400px;

  @include media("<768px") {
    flex-direction: column;
    max-width: 100%;
  }


  &__item+ &__item {
    margin-left: 10px;
    @include media("<768px") {
      margin-left: 0;
      margin-top: 10px;
    }
  }
  &__item {
    flex: 1;
  }
  
  &__button {
    cursor: pointer;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    font-size: 18px;
    color: $greyMain;
    padding: 10px 0;
    transition: all .3s ease;
    border: 1px dashed rgba($greyMain, .5);
    border-radius: 5px;
    font-family: $mainSansSerifBold;

    &:hover {
      color: $primaryColor;
      border-color: $primaryColor;
    }

    .anticon {
      margin-right: 10px;

      @include media('<568px') {
        margin-right: 0;
        font-size: 21px;
      }
    }

    &.active {
    border: 1px solid $primaryColor;
      color: $primaryColor;
      background-color: rgba($primaryColor, .15);
    }
  }
}