@import "vars";
@import "mixins";
@import "../assets/styles/helpers";
@import "layouts/main";
@import "common/general";
@import "ui/index.scss";
@import "modules/index.scss";
@import "./landing";
@import "../assets/styles/main";
::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

/* Track */

::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: $primaryColor;
}
body {
  background: $dark;
  overflow-x: auto;
}
.ant-layout {
  overflow-x: hidden;
}

html,
body,
#root {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: $mainSansSerif;
  font-size: 14px;
}

body.modal-opened {
  overflow: hidden;
}

.landing-pages {
  color: #fff;

  h1,h2,h3,h4,h5,h6 {
    color: #fff;
  }
  .ant-typography {
    color: #fff;
  }

  form {
    input,
    textarea {
      margin: 20px auto 0;
      padding: 14px 24px;
      width: 100%;
      background: $defaultWhite;
      height: 45px;
      border: none;
      -webkit-border-radius: 30px;
      -moz-border-radius: 30px;
      border-radius: 30px;
      color: $titleBlack;
      font-size: 14px;
      line-height: 16px;
      box-shadow: 0 15px 30px $slideBoxShadow;
      resize: none;

      &::placeholder {
        display: inline;
        vertical-align: top;
      }

      &:focus,
      :active {
        outline: none;
        padding: 10px 22px;
        border: 2px solid $borderBlue;
      }
    }
  }
}

a {
  color: $primaryColor;
  &:hover {
    color: $secondaryColor;
  }
}

.ant-checkbox{
  &:after {
    display: none;
  }
  &-inner {
    background: $inactive;
    width: 24px;
    height: 24px;
    border: none !important;
    outline: none;
    box-shadow: none;
    &::before {
      content: "";
      width: 16px;
      height: 12px;
      background: url("../images/icons/check-icon.svg") no-repeat center / contain;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: opacity .2s ease;
    }
  }
  &-checked {
    .ant-checkbox {
      &-inner {
        background: $primaryColor;
        &::after {
          display: none;
        }
        &::before {
          opacity: 1;
        }
      }
    }
  }
}

.ant-select-tree-checkbox {
  &:after {
    display: none;
  }
  &-inner {
    border-color: $primaryColor !important;
  }
  &-checked {
    .ant-select-tree-checkbox {
      &-inner {
        background: $primaryColor;
        border-color: $primaryColor;
      }
    }
  }
}
.ant-switch-checked{
  &::after {
    background-color: $primaryColor;
  }
}
.ant-select-single{
  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      border-radius: 5px;
      display: flex;
      align-items: center;
      &:hover {
        border-color: $primaryColor;

      }

      .ant-select-selection-item {
        background: $primaryColor;
        color: $alwaysWhite;
        border-radius: 5px;
        line-height: 25px;
        margin: 0;
      }
    }
    .ant-select-selection-placeholder {
      padding-top: 0;
    }
  }
  &.ant-select-focused {
    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        border-color: $primaryColor !important;
        box-shadow: none !important;
      }
    }
  }
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #61C1B6;
  color: #fff;
  border-radius: 5px;
}
.radio-select-dropdown {

  > div {
    background: #29425A;

  }
  .ant-select-item-option-selected .radio-select-option-inner {
    border-color: $alwaysWhite;
    &:after {
      background: $alwaysWhite;
    }
  }
  &::before {
    border-bottom-color: #29425A;
  }

}
.ant-popover {
  .ant-popover-arrow {
    border-color: #29425A !important;
  }
  .ant-popover-inner {
    background: #29425A;
  }
}
.ant-spin-dot-item {
  background-color: $primaryColor;
}
.ant-input-affix-wrapper,
.ant-cascader-input.ant-input {
  border-radius: 5px;
  &:hover {
    border-color: $primaryColor;
  }
}
.ant-cascader-picker {
  &:hover {
    .ant-cascader-input {
      border-color: $primaryColor !important;
    }
  }
  &:focus {
    .ant-cascader-input {
      border-color: $primaryColor !important;
      box-shadow: 0 0 7px -3px $primaryColor;
    }
  }
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled), .ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
  background-color: $primaryColor;
  color: $alwaysWhite;
  path {
    fill: $alwaysWhite;
  }
}
.ant-select-multiple .ant-select-selection-item {
  border-radius: 5px;
  background-color: $primaryColor;
  color: $alwaysWhite;
  path {
    fill: $alwaysWhite;
  }
}
.ant-typography,
.ant-card-head-title,
.ant-form-item-label,
.ant-table-thead {
  font-family: $mainSansSerifBold;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
  background-color: $primaryColor !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: $primaryColor !important;
  border-color: rgba($primaryColor, .5) !important;

}
.ant-picker-cell-in-view.ant-picker-cell-today {
  font-family: $mainSansSerifBold;
  color: #fff;
  opacity: 1;

  .ant-picker-cell-inner {
    background: $primaryColor !important;

  }
  .ant-picker-cell-inner::before {
    border: 1px solid transparent;
    display: none;
  }
}
.ant-picker-cell-in-view .ant-picker-cell-inner::after,
.ant-picker-cell-in-view .ant-picker-cell-inner::before{
  background: rgba($primaryColor, .5) !important;
  border-color: rgba($primaryColor, .5) !important;

}
.ant-picker-cell-in-view {
  &::before,
  &::after {
    border-color: rgba($primaryColor, .5) !important;
  }
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-time-panel-column
> li.ant-picker-time-panel-cell-selected
.ant-picker-time-panel-cell-inner {
  background: rgba($primaryColor, .5) !important;
}
.country-item {
  display: flex;
  align-items: center;
  white-space: nowrap;

  img {
    width: 35px;
    margin-right: 5px;
  }
}

img {
  max-width: 100%;
}