.order-card {
  color: $alwaysWhite;
  background: $primaryColor;
  text-align: justify;

  .ant-card-head-title {
    color: $alwaysWhite;
  }
  .ant-card-body {
    padding: 0 24px 24px;
  }
}