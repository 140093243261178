.info-item {
  font-size: 21px;
  line-height: 23px;
  text-align: left;
  font-family: $mainSansSerifBold;

  &__label {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $greyMain;
    margin-top: 10px;
    font-family: $mainSansSerif;

  }

  &--center {
    text-align: center;
  }

  &--right {
    text-align: right;
  }
}