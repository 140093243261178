.return-admin {
  position: fixed;
  right:15px;
  top: 70px;
  background-color: $primaryMain;
  opacity: 0.75;
  border-radius: 20px;
  padding: 10px;
  z-index: 999;
  cursor: pointer;
  transition: all .2s ease;
  &:hover {
    opacity: 1;
  }
}