.chat-preview {
  + * {
    margin-top: 10px;
  }
  transition: all .3s ease;
  position: relative;
  .ant-card-body {
    padding: 0;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #000;
    padding: 10px 15px;
  }

  &__control-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    border: none;
    z-index: 5;
    background: none;
    font-size: 21px;
    padding: 0;

    path {
      fill: $greyMain;
      transition: all .3s ease;
    }

    &:hover {
      path {
        fill: $secondaryMain;
      }
    }


    &.active {

      path {
        fill: $primaryMain;
      }
    }

  }

  &__theme {
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 5px;
    line-height: 1;
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: 1px solid $alwaysGrey;
    width: 100%;

    @include media('<old') {
      flex-direction: column;
      align-items: flex-start;
    }

    @include media('<568px') {
      font-size: 12px;
    }
  }

  &__msg {
    font-size: 12px;
    font-style: italic;
    white-space: nowrap;
    width: 100%;
    display: flex;
    align-items: center;
  }

  &__msg-content {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__created-at {
    margin-left: auto;

    @include media('<old') {
      margin-left: 0;
      margin-top: 5px;
    }
  }

  &__last-msg {
    flex: none;
    margin-right: 10px;
  }
  &:hover {
    transform: scale(1.01);
    z-index: 10;
    border-color: $primaryColor;


    .chat-preview__info {
      color: $primaryColor;
    }
  }
  &--unread {
    border-color: $primaryColor;
    box-shadow: 0 0 5px -2px $primaryColor;

    .chat-preview {
      &__info {
        //color: $secondaryMain;
      }
    }
  }
  &--resend {
    .chat-preview {
      &__bookmark {
        margin-top: -15px;
      }
      &__resend {
        margin-top: 15px;
      }
      &__info {
        //color: $secondaryMain;
      }
    }
  }
  &--admin-msg {
    .chat-preview {
      &__info {
        padding-left: 45px;
      }
    }
  }
}

.msg-date {
  font-weight: normal;
  font-size: 10px;
  font-style: italic;

  &__value {
    font-weight: bold;
  }
}