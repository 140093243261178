.clipboard-copy {
  position: relative;
  display: flex;
  align-items: center;

  &__input {
    opacity: 0;
    width: 3px;
    height: 1px;
    padding: 0;
    margin: 0;
  }
  &__icon {
    cursor: pointer;
    vertical-align: middle;
    margin-left: 5px;
    min-width: 20px;
    min-height: 20px;

    path {
      fill: $primaryColor;
    }


    &--md {
      min-width: 25px;
      min-height: 25px;
    }
  }
}