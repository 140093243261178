.ant {
  &-steps {
    background: $alwaysWhite;
    border-radius: 10px;

    &-navigation {
      padding: 0;
      .ant{
        &-steps{
          &-item {
            padding-top: 12px;
            margin: 0 !important;
            text-align: left;
            padding-left: 40px !important;
            &:before {
              background: $primaryMain;
            }
            &:after {
              content: none;
            }

          }
        }
      }
    }
    &-item {
      & +  .ant-steps-item {
        border-left: 1px solid $stroke;
      }
      &-active {
        .step-icon {
          color: $alwaysWhite;
          background: $primaryColor;
        }
      }
      &-finish {
        .step-icon {
          color: $alwaysWhite;
          background: $primaryColor;
        }
      }

      &-description {
        color: $greyMain !important;
        max-width: 100% !important;

        .selected-description {
          color: $primaryColor;
        }
      }

      &-icon {
        margin-right: 22px;
      }
      &-container {
        width: 100%;
      }
      &-content {
        width: 83%;
      }
    }
  }
}

.steps-content {
  margin-top: 28px;
}

.steps-action {
  display: flex;
  position: sticky;
  left: 0;
  justify-content: center;
  bottom: -20px;
  background: #fff;
  padding: 10px 0;
}