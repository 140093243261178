.ant {
  &-btn {
    font-weight: 500;
    line-height: 8px;
    border-radius: 5px;
    background: transparent;
    font-family: $mainSansSerifBold;

    &:hover,
    &:active,
    &:focus{
      color: $primaryColor;
      border-color: $primaryColor;
      background: transparent;
    }

    &-lg {
      height: 45px;
    }

    &-circle {
      height: 45px;
      width: 45px;
      border-radius: 5px;
      &.ant-btn-lg {
        height: 45px;
        width: 45px;
        border-radius: 5px;
      }
    }

    &-round {
      display: inline-flex;
      align-items: center;

      &.ant-btn-lg {
        height: auto;
        font-size: 14px;
        padding: 10px 20px;
        line-height: 25px;
        border-radius: 4px;

      }
    }

    &-primary {
      @include btn-primary;
    }

    &-secondary {
      @include btn-secondary;
    }

    &-tertiary {
      @include btn-tertiary;
    }

    &-default {
      @include btn-default;
    }

    &-with-icon {
      @include with-icon;
    }

    &-loading {
      @include loading;
    }
  }
}
