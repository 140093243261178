.translation-list-item {
  margin-top: 20px;

  &__edit {
    margin-left: auto;
    margin-right: 10px;
  }

  &__top {
    display: flex;
    align-items: center;
  }

  &__title {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-right: auto;

    .anticon {
      margin-right: 5px;
    }

    .ant-typography {
      margin: 0;
    }
  }
  .ant-divider-horizontal {
    margin: 10px 0;
  }
}

.translation-list-pagination {
  text-align: center;
  margin-top: 20px;
}

.translation-list-filters {
  &__input {
    margin-bottom: 5px;
  }
}