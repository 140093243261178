.promo-card {
  position: relative;
  cursor: pointer;
  height: 142px;
  opacity: .5;
  border: 1px solid rgba($darkBox, .5);

  &__selected {
    opacity: 1;
    .promo-card__img {
      opacity: 1;
    }
  }

  .ant-card-body {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0;
  }

  .ant-checkbox {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__img {
    width: 30%;
    height: 100%;
    border-right: 1px solid $stroke;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.6;
  }

  &__info {
    width: 70%;
    padding: 0 20px;
    font-size: 24px;
    font-family: $mainSansSerifBold;

  }

  &__lg {
    height: 142px;
  }

  &__md {
    height: 101px;
  }
}