.ant{
  &-menu {
    font-family: $mainSansSerifBold;
    svg {
      path {
        fill: $primaryMain;
      }
    }
    &-item {


      &:hover {
        color: $primaryMain;
        border-color: $primaryMain !important;

        & > a {
          color: $primaryMain !important;

          .icon-custom,
          .icon-custom-secondary {
            path {
              fill: $primaryMain;
            }
          }
        }
      }

      &-selected {
        background-color: transparent !important;
        &:after {
          border-color: $primaryMain !important;
        }

        color: $primaryMain !important;
        border-color: $primaryMain !important;

        & > a {
          color: $primaryMain !important;

          & > i {
            color: $primaryMain !important;
          }
        }
      }
      & > a {
        & > i {
          color: $primaryColor;
        }

        &:hover {
          color: $primaryMain;
          & > i {
            color: $primaryMain;
          }
        }
      }
    }
    &-submenu-title {
      &:hover {
        .ant-menu-submenu-arrow {
          color: $primaryColor;
          &::before, &::after {
            background: $primaryColor;
          }
        }

      }
      span {
        padding-left: 24px;
      }
    }
    &-submenu {
      .ant-menu-submenu-arrow {
        &::before,
        &::after {
          background: #000;
          transition: all .2s ease;
        }
      }
      &-active {
        .ant-menu-submenu-title {
          color: $primaryColor;
        }
        .ant-menu-submenu-arrow {
          &::before,
          &::after {
            background: $primaryColor !important;
          }
        }
      }
    }
    &-sub {
      .ant-menu-item {
        padding-left: 40px;
        color: $alwaysBlack;
        &:hover {
          color: $primaryColor;
        }
      }
    }
  }
}

.ant-input {
  border-radius: 5px !important;
}
.ant-select-selector {
  border-radius: 5px !important;
  &:hover {
    border-color: $primaryColor !important;
  }
}

.ant-select-focused {
  .ant-select-selector {
    border-color: $primaryColor !important;
    box-shadow: 0  0 7px -2px $primaryColor !important;
  }
}